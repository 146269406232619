import { useAgentApi } from '../../hooks/useAgentApi'
import { DefaultService } from '../../api/agentapi'
import { milliseconds } from 'date-fns'
import { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'

const WorkspaceSyncStatusInterval: Duration = { seconds: 2 }

export enum SyncStatus {
  Synced,
  Syncing,
  Paused,
  Error,
}
export const useSyncProgress = ({
  repoId,
  workspaceId,
  refreshInterval = WorkspaceSyncStatusInterval,
}: {
  repoId: string
  workspaceId: string
  refreshInterval?: Duration
}) => {
  const { data: workspaceStatusRes } = useAgentApi(
    `fetch-workspace-status-${workspaceId}`,
    async () => {
      try {
        const data = await DefaultService.getSyncProgress({
          repoId: repoId,
          workspaceId: workspaceId,
        })
        return { data }
      } catch (error: any) {
        return { error }
      }
    },
    false,
    { refreshInterval: milliseconds(refreshInterval) }
  )
  const { syncStatus, syncingInfo } = useMemo(() => {
    if (!!workspaceStatusRes?.error || workspaceStatusRes?.data?.LastErr) {
      return { syncStatus: SyncStatus.Error, syncingInfo: workspaceStatusRes?.data?.LastErr }
    }
    if (workspaceStatusRes?.data?.IsPaused) {
      return { syncStatus: SyncStatus.Paused }
    }
    if (isEmpty(workspaceStatusRes?.data?.CurrentSyncAction)) {
      return { syncStatus: SyncStatus.Synced }
    }
    const syncProgress = workspaceStatusRes?.data
    const remainingActions =
      (syncProgress?.FileStats?.Outbound?.ItemsCount || 0) + (syncProgress?.FileStats?.Inbound?.ItemsCount || 0)
    const infoSuffix = remainingActions > 1 ? ` and ${remainingActions - 1} more` : ''
    return { syncStatus: SyncStatus.Syncing, syncingInfo: `${syncProgress?.CurrentSyncAction}${infoSuffix}` }
  }, [workspaceStatusRes])

  return { syncStatus, syncingInfo }
}
