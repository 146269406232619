import { Handle, NodeProps, Position } from 'reactflow'
import React from 'react'

import { UserAvatar } from '../base/UserAvatar'
import { User } from '../../api/coreapi'
import styled from '@emotion/styled'

interface CustomCommitNodeData {
  onNodeClick: () => void
  author: User
  commit_message: string
  branch_name?: string
  isLatest?: boolean
}

const CommitNodeContainer = styled.div<CustomCommitNodeData>`
  width: 40px;
  height: 45px;
  color: ${({ theme }) => theme.colors.white.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
`

const BranchName = styled.div`
  position: absolute;
  top: -20px;
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.colors.black.primary};
  white-space: nowrap;
`

export const CommitNode = ({ data }: NodeProps<CustomCommitNodeData>) => (
  <CommitNodeContainer {...data}>
    <div onClick={data.onNodeClick}>
      <UserAvatar
        picture={data.author.image}
        name={data.author.full_name || data.author.name || data.author.id}
        email={data.author.email || ''}
        hideTooltip={true}
      />
    </div>
    {data.isLatest && <BranchName>{data.branch_name}</BranchName>}
    <Handle type="source" position={Position.Right} />
    <Handle type="target" position={Position.Left} />
  </CommitNodeContainer>
)
