import { BlobUploadUrl, FileMutationService, StorageBackend, StorageUri } from './coreapi'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { postWithProgressAsync } from './fetcher'
// @ts-ignore
import sha1 from 'js-sha1'
import { CalculateFileSha } from '../hooks/useFileShaWorker'

export const uploadFileToRepoAsync = async (
  repoId: string,
  file: File,
  worker: CalculateFileSha,
  onProgress: (progressPercentage: number) => void
): Promise<{ storage_backend: StorageBackend; storage_uri: StorageUri; sha1: string; size: number }> => {
  const sha = await worker(file)
  return await uploadToRepoAsync(repoId, sha, file, file.size, onProgress)
}

export const uploadContentsToRepoAsync = async (
  repoId: string,
  contents: string
): Promise<{ storage_backend: StorageBackend; storage_uri: StorageUri; sha1: string; size: number }> => {
  const sha = sha1.create().update(contents).hex()
  return await uploadToRepoAsync(repoId, sha, contents, contents.length)
}

async function uploadToRepoAsync(
  repoId: string,
  sha: string,
  contents: string | File,
  size: number,
  onProgress?: (progressPercentage: number) => void
) {
  const uploadInfo = (await FileMutationService.srcHandlersv2FilesGetBlobUploadLink({
    repoId,
    sha1: sha,
  })) as BlobUploadUrl
  const uploadUrl = uploadInfo.upload_url?.url
  if (!isEmpty(uploadUrl)) {
    const formData = new FormData()
    const fields = uploadInfo.upload_url!.fields
    Object.keys(fields).forEach((field) => {
      formData.append(field, get(fields, field))
    })
    formData.append('file', contents)
    await postWithProgressAsync(uploadUrl!, {}, formData, onProgress || (() => {}))
  }
  return { ...uploadInfo.location, sha1: sha, size: size }
}
