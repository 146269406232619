import { PrimaryButton } from '../../base/PrimaryButton'
import { BannerPadding } from '../../base/PaddingStyle'
import styled from '@emotion/styled'
import { FlexRow } from '../../base/Flex'
import { useCallback, useState } from 'react'
import WarningIcon from '@mui/icons-material/Warning'
import { MergeId, RepositoryWorkspaceManipulationService } from '../../../api/coreapi'
import { callAsync } from '../../../utils/callAsync'
import { errorToast, infoToast } from '../../../utils/toast'
import isEmpty from 'lodash/isEmpty'
import { routeToMerge } from '../../../Routes'
import { useNavigate } from 'react-router'

const Container = styled(FlexRow)`
  ${BannerPadding};
  text-align: center;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.blue.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.stroke};
`

const WarningText = styled(FlexRow)`
  color: ${({ theme }) => theme.colors.black.primary};
`

type ConflictWarningProps = {
  repoId: string
  workspaceId: string
}
export const ConflictWarning = ({ repoId, workspaceId }: ConflictWarningProps) => {
  const { loading, forwardWorkspace } = useForwardWorkspace(repoId, workspaceId)
  return (
    <Container>
      <WarningText gap={0.5}>
        <WarningIcon />
        Updates available on current branch
      </WarningText>
      <PrimaryButton disabled={loading} onClick={forwardWorkspace}>
        Update
      </PrimaryButton>
    </Container>
  )
}

const useForwardWorkspace = (repoId: string, workspaceId: string) => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const forwardWorkspace = useCallback(() => {
    return callAsync(
      async () => {
        const result = await RepositoryWorkspaceManipulationService.srcHandlersv2WorkspaceForwardWorkspace({
          repoId,
          workspaceId,
        })
        const mergeId = (result as MergeId)?.merge_id
        const hasConflicts = !isEmpty(mergeId)
        if (hasConflicts) {
          infoToast('Conflicts discovered in workspace update', true)
          navigate(routeToMerge(repoId, mergeId))
        } else {
          infoToast('Workspace updated successfully', true)
        }
      },
      setLoading,
      () => errorToast('Error updating workspace. Please try again.')
    )
  }, [repoId, workspaceId, navigate])

  return { loading, forwardWorkspace }
}
