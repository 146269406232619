import { useEffect, useRef } from 'react'
import { useKeyPress } from '../../hooks/useKeyPress'
import { isFocused } from '../../utils/focus'
import * as KeyCode from 'keycode-js'

export const useFocusedButtons = (enabled: boolean, primaryAction: () => void, secondaryAction: () => void) => {
  const primaryButtonRef = useRef<HTMLDivElement>(null)
  const secondaryButtonRef = useRef<HTMLDivElement>(null)

  useKeyPress('Escape', () => secondaryAction(), enabled)
  useKeyPress(
    (event) =>
      event.key === KeyCode.CODE_ENTER &&
      (isFocused(secondaryButtonRef.current) || isFocused(primaryButtonRef.current)),
    () => {
      if (isFocused(secondaryButtonRef.current)) {
        secondaryAction()
      } else if (isFocused(primaryButtonRef.current)) {
        primaryAction()
      }
    },
    enabled
  )

  useEffect(() => {
    if (enabled) {
      primaryButtonRef.current?.focus()
    }
  }, [enabled])

  return [primaryButtonRef, secondaryButtonRef]
}
