import React, { ReactNode } from 'react'
import styled from '@emotion/styled'
import CodeOutlined from '@mui/icons-material/CodeOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import WavingHandIcon from '@mui/icons-material/WavingHand'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ChatIcon from '@mui/icons-material/Chat'
import BillingIcon from '@mui/icons-material/Payment'
import { signOutAsync } from '../../api/auth'
import { Loader } from '../base/Loader'
import { UserAvatar } from '../base/UserAvatar'
import { Styleable } from '../../theme'
import { TextBold } from '../base/TextStyle'
import { useCurrentUser } from '../../hooks/api/useCurrentUser'
import { OptionsDropdown } from '../dropdown/OptionsDropdown'
import { useNavigate } from 'react-router'
import { routeToApiTokens, routeToBilling, routeToWelcome } from '../../Routes'
import { FlexColumn, FlexFiller, FlexRow } from '../base/Flex'
import { openInNewTab, rawNavigate } from '../../utils/navigateRaw'
import config from '../../env/config'
import { AnalyticsCallback, useAnalytics } from '../../hooks/api/useAnalytics'
import { NavigateFunction } from 'react-router/dist/lib/hooks'
import { AccountService, BillingSession } from '../../api/coreapi'
import { errorToast } from '../../utils/toast'
import { log } from '../../utils/log'
import { useIsDarkTheme } from '../../hooks/useIsDarkTheme'
import { Switch } from '@mui/material'
import DarkMode from '@mui/icons-material/DarkMode'
import LightMode from '@mui/icons-material/LightMode'

const Bold = styled.span`
  ${TextBold};
`

type Item = {
  key: string
  title: ReactNode
  onSelect?: (analyticCallback: AnalyticsCallback, navigate: NavigateFunction) => void
  disabled?: boolean
  isDivider?: boolean
  keepOpenAfterSelect?: boolean
}

const ItemRow = ({ text, icon }: { text: string; icon: ReactNode }) => (
  <FlexRow gap={0.5} centered={true}>
    {icon}
    {text}
  </FlexRow>
)

const SignedInAsSection = styled(FlexColumn)`
  color: ${({ theme }) => theme.colors.blue.contrastText};
`

export const LoggedInUserAvatar = ({ className }: Styleable) => {
  const navigate = useNavigate()
  const { userData, loading } = useCurrentUser()
  const postAnalytics = useAnalytics()
  const [isDarkTheme, setIsDarkTheme] = useIsDarkTheme()

  const items: Item[] = [
    {
      key: 'name',
      title: (
        <SignedInAsSection centered={true}>
          <div>Signed in as</div>
          <Bold>{userData?.name || userData?.email}</Bold>
        </SignedInAsSection>
      ),
      disabled: true,
    },
    {
      key: 'darkmode',
      title: (
        <FlexRow centered={true} gap={0.5}>
          {isDarkTheme ? <DarkMode /> : <LightMode />}
          Dark mode
          <FlexFiller />
          <Switch checked={isDarkTheme} color="default" onChange={() => setIsDarkTheme(!isDarkTheme)} />
        </FlexRow>
      ),
      onSelect: () => setIsDarkTheme(!isDarkTheme),
      keepOpenAfterSelect: true,
    },
    {
      key: 'divider1',
      title: '',
      isDivider: true,
    },
    {
      key: 'billing',
      title: (
        <FlexRow gap={0.5} centered={true}>
          <BillingIcon />
          Plan & Billing
        </FlexRow>
      ),
      onSelect: async () => {
        const returnLocation = window.location.href
        const pathname = window.location.pathname
        navigate(routeToBilling())
        function isBillingSession(session: any): session is BillingSession {
          return session.location !== undefined && session.location !== ''
        }
        try {
          const billingService = await AccountService.srcHandlersv2AccountGetBillingSession({
            returnUrl: returnLocation,
          })
          if (isBillingSession(billingService)) {
            rawNavigate(billingService.location)
          } else {
            throw new Error('Failed to load billing page')
          }
        } catch (e) {
          log.error(e)
          navigate(pathname, { replace: true })
          errorToast('Failed loading billing page. Please contact support.')
        }
      },
    },
    {
      key: 'integrations',
      title: (
        <FlexRow gap={0.5} centered={true}>
          <CodeOutlined />
          Integrations
        </FlexRow>
      ),
      onSelect: (_, navigate) => navigate(routeToApiTokens()),
    },
    {
      key: 'welcome',
      title: <ItemRow text="Welcome Page" icon={<WavingHandIcon />} />,
      onSelect: (_, navigate) => navigate(routeToWelcome()),
    },
    {
      key: 'docs',
      title: <ItemRow text="Documentation" icon={<MenuBookIcon />} />,
      onSelect: (analyticCallback) => openInNewTab(config.QUICKSTART_LINK, analyticCallback),
    },
    {
      key: 'feature-request',
      title: <ItemRow text="Feature Request" icon={<ArrowCircleUpIcon />} />,
      onSelect: (analyticCallback) => openInNewTab(config.FEATURE_REQUEST_LINK, analyticCallback),
    },
    {
      key: 'contact',
      title: <ItemRow text="Contact by email" icon={<MailOutlineIcon />} />,
      onSelect: (analyticCallback) => openInNewTab(`mailto:${config.SUPPORT_EMAIL}`, analyticCallback),
    },
    {
      key: 'discord',
      title: <ItemRow text="Chat on Discord" icon={<ChatIcon />} />,
      onSelect: (analyticCallback) => openInNewTab(config.DISCORD_LINK, analyticCallback),
    },
    {
      key: 'divider2',
      title: '',
      isDivider: true,
    },
    {
      key: 'signout',
      title: (
        <FlexRow gap={0.5} centered={true}>
          <LogoutOutlinedIcon />
          Sign out
        </FlexRow>
      ),
      onSelect: () => signOutAsync(),
    },
  ]

  return (
    <OptionsDropdown
      className={className}
      items={items}
      onItemSelected={(item) => item.onSelect?.(postAnalytics, navigate)}
      button={
        loading || !userData ? (
          <Loader />
        ) : (
          <UserAvatar picture={userData.picture} name={userData.name} email={userData.email} hideTooltip />
        )
      }
      arrow={false}
    />
  )
}
