import { RepoSelector } from '../selector/RepoSelector'
import { ExpandableProps, RefDropdown } from './RefDropdown'
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react'
import { log } from '../../utils/log'
import { routeToRepo, routeToWelcome } from '../../Routes'
import { useNavigate } from 'react-router-dom'
import { useRepos } from '../../hooks/api/useRepos'
import styled from '@emotion/styled'
import { useNewRepoDialog } from '../dialogs/useNewRepoDialog'
import { useImportRepoDialog } from '../dialogs/useImportRepoDialog'
import { IsDesktopApp } from '../../desktop/components/utils/IsDesktopApp'
import { useInitRepo } from '../../desktop/hooks/useInitRepo'

type Props = ExpandableProps & {
  repoId?: string
  inRepolessContext: boolean
  setDropdownWidth: (width: number) => void
  setActionDialogOpen: Dispatch<SetStateAction<boolean>>
}

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${({ theme }) => theme.padding.m}rem ${({ theme }) => theme.padding.l}rem;
  gap: ${({ theme }) => theme.padding.l}rem;
  div {
    width: 100%;
  }
`

export const RepoDropdown = ({
  repoId,
  expanded,
  setExpanded,
  inRepolessContext,
  setDropdownWidth,
  setActionDialogOpen,
}: Props) => {
  const navigate = useNavigate()
  const { repos, loading, refresh } = useRepos()
  const selectedRepo = useMemo(
    () => (repoId ? repos?.find((repo) => repo.repo_id === repoId) : undefined),
    [repoId, repos]
  )

  const onDialogOpen = useCallback(
    (open: boolean) => {
      if (open) {
        setExpanded(false)
      }
      setActionDialogOpen(open)
    },
    [setActionDialogOpen, setExpanded]
  )

  const { Dialog: NewRepoDialog, Button: NewRepoButton } = useNewRepoDialog({
    onCreated: (repoId: string) => {
      refresh()
      setExpanded(false)
      navigate(routeToRepo(repoId))
    },
    onOpenChanged: (open: boolean) => {
      onDialogOpen(open)
    },
    locationContext: 'RepoDropdown',
  })
  const { Dialog: ImportRepoDialog, Button: ImportRepoButton } = useImportRepoDialog({
    onOpenChanged: (open: boolean) => {
      onDialogOpen(open)
    },
    locationContext: 'RepoDropdown',
  })

  const {
    Dialog: InitRepoDialog,
    Button: InitRepoButton,
    isOpen: isInitRepoOpen,
  } = useInitRepo('RepoDropdown', onDialogOpen, refresh)

  useEffect(() => {
    if (!repoId && !inRepolessContext && repos?.length === 1) {
      const singleRepoId = repos[0]!.repo_id
      log.info('Found only a single repo - selecting it', { repoId: singleRepoId })
      navigate(routeToRepo(singleRepoId), { replace: true })
    }
  }, [navigate, repos, repoId, inRepolessContext])

  const noRepos = repos?.length === 0
  useEffect(() => {
    if (noRepos && !IsDesktopApp()) {
      navigate(routeToWelcome(), { replace: true })
    }
  }, [navigate, noRepos])

  return (
    <>
      <NewRepoDialog />
      <ImportRepoDialog />
      {isInitRepoOpen && <InitRepoDialog />}
      <RefDropdown
        loading={loading}
        title={repoId ? 'Current Repo' : 'Select a Repo'}
        selectedTitle={selectedRepo?.repo_name}
        expanded={expanded}
        setExpanded={setExpanded}
        disableExpandStateControl={!repoId && !inRepolessContext}
        setDropdownWidth={setDropdownWidth}
        offsetLeftPx={0}
      >
        {repos && (
          <>
            <ButtonsRow>
              {IsDesktopApp() ? <InitRepoButton /> : <NewRepoButton />} <ImportRepoButton />
            </ButtonsRow>
            <RepoSelector repos={repos} selectedRepoId={repoId} />
          </>
        )}
      </RefDropdown>
    </>
  )
}
