import { Dropdown } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import { Styleable } from '../../theme'
import isNil from 'lodash/isNil'
import styled from '@emotion/styled'
import { TextRegular } from '../base/TextStyle'

type Item = {
  key: string
  icon?: ReactNode
  title: string | ReactNode
  isDivider?: boolean
  disabled?: boolean
  onSelected?: () => void
  keepOpenAfterSelect?: boolean
}

type Props<T extends Item> = Styleable & {
  items: T[]
  button?: ReactNode
  selectedItem?: T
  onItemSelected?: (item: T) => void
  disabled?: boolean
  open?: boolean
  onOpenChanged?: (open: boolean) => void
  arrow?: boolean
}

const MenuItemComponent = ({ title, onClick }: { title: ReactNode; onClick: () => void }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        onClick()
      }}
    >
      {title}
    </div>
  )
}

const ButtonText = styled.span`
  ${TextRegular};
  color: ${({ theme }) => theme.colors.black.primary};
`

export const OptionsDropdown = <T extends Item>({
  className,
  items,
  button,
  selectedItem,
  onItemSelected,
  disabled,
  open,
  onOpenChanged,
  arrow = true,
}: Props<T>) => {
  const [hidden, setHidden] = useState<boolean>()
  useEffect(() => {
    if (!isNil(hidden)) {
      setHidden(undefined)
    }
  }, [hidden])
  return (
    <Dropdown
      menu={{
        items: items.map((item) =>
          item.isDivider
            ? { key: item.key, type: 'divider' }
            : {
                key: item.key,
                label: (
                  <MenuItemComponent
                    title={item.title}
                    onClick={() => {
                      if (item.onSelected) {
                        item.onSelected()
                        !item.keepOpenAfterSelect && setHidden(true)
                      }
                      if (onItemSelected) {
                        onItemSelected(item)
                        !item.keepOpenAfterSelect && setHidden(true)
                      }
                    }}
                  />
                ),
                disabled: item.disabled,
              }
        ),
      }}
      placement="bottom"
      arrow={arrow}
      disabled={disabled}
      open={isNil(hidden) ? open : !hidden}
      onOpenChange={onOpenChanged}
      trigger={['click']}
    >
      <div className={className} onClick={(e) => e.stopPropagation()}>
        <ButtonText>{button || selectedItem?.title}</ButtonText>
      </div>
    </Dropdown>
  )
}
