import { osName, OsTypes } from 'react-device-detect'
import { IsDesktopApp } from '../desktop/components/utils/IsDesktopApp'

const hostname = window.location.host
const envName = hostname.startsWith('localhost')
  ? 'localhost'
  : hostname.includes('.test.') || hostname.includes('-test.')
  ? 'test'
  : 'prod'

const getHostname = (url) => url && new URL(url).hostname

const mbToBytes = (mb) => mb * 1024 * 1024

const appName = IsDesktopApp() ? 'desktopapp' : 'webapp'
const sentryDSN = IsDesktopApp()
  ? 'https://93a59c52df6b92c273821ec23fb462cd@o1210717.ingest.sentry.io/4507367664320517'
  : 'https://f5db9b80684244cda1de6f507a695ef4@o1210717.ingest.sentry.io/4504316159787008'

const config = {
  USER_POOL_REGION: process.env.REACT_APP_USER_POOL_REGION,
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  USER_POOL_CLIENT_ID: IsDesktopApp()
    ? process.env.REACT_APP_DESKTOP_USER_POOL_CLIENT_ID
    : process.env.REACT_APP_USER_POOL_CLIENT_ID,
  USER_POOL_CLIENT_ID_PAT: process.env.REACT_APP_USER_POOL_CLIENT_ID_PAT,
  WEBAPP_URL: process.env.REACT_APP_WEBAPP_URL,
  DESKTOP_DEEP_LINK_DOMAIN: process.env.REACT_APP_DESKTOP_DEEP_LINK_DOMAIN,
  TOKENS_APP_URL: process.env.REACT_APP_TOKENS_APP_URL,
  APP_NAME: appName,
  APP_VERSION: process.env.REACT_APP_VERSION_TAG || '0.0.0',
  API_URL: process.env.REACT_APP_API_URL,
  API_DOMAIN: getHostname(process.env.REACT_APP_API_URL),
  AGENT_PORT: process.env.REACT_APP_AGENT_PORT,
  AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN,
  IS_DEV_ENV: envName !== 'prod',
  ENVIRONMENT: envName,
  SENTRY_DSN: sentryDSN,
  MAXIMUM_DISPLAYED_FILE_SIZE_BYTES: mbToBytes(2),
  INTERNAL_USER_EMAIL_SUFFIX: '@diversion.dev',
  IS_INTERNAL_USER: false,
  PAGE_SIZE: 50,
  QUERY_MIN_LENGTH: 3,
  DISCORD_LINK: 'https://discord.gg/9UtVyDkPS2',
  QUICKSTART_LINK: 'https://docs.diversion.dev/quickstart',
  FEATURE_REQUEST_LINK: 'https://portal.productboard.com/diversion/1-diversion-public-roadmap/',
  TOS_LINK: 'https://www.diversion.dev/terms-of-service',
  PRIVACY_POLICY_LINK: 'https://www.diversion.dev/privacy-policy',
  SUPPORT_EMAIL: 'support@diversion.dev',
  MAIN_SITE: 'https://www.diversion.dev',
  UNREAL_PLUGIN_ID: '7c8abd783acd4d66a5290a12a5d67523',
  JB_PLUGIN_ID: '21144',
  CLI_EXECUTABLE: (envName === 'prod' ? 'dv' : 'dvt') + (osName === OsTypes.Windows ? '.exe' : ''),
  GITHUB_APP_SLUG: 'diversion-sync',
  TOAST_DEDUP_TIMEOUT: '1m',
  REQUEST_TIMEOUT_SEC: 120,
  AUTH_TOKEN_MODE: process.env.REACT_APP_MODE === 'tokens',
}

export const exposeDebugInfo = () => config.IS_DEV_ENV || config.IS_INTERNAL_USER

export default config
