import { Branch, Workspace } from '../../api/coreapi'
import { useUrlState } from '../../hooks/useUrlState'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import RampLeftOutlinedIcon from '@mui/icons-material/RampLeftOutlined'
import RampRightOutlinedIcon from '@mui/icons-material/RampRightOutlined'
import MoveUpOutlined from '@mui/icons-material/MoveUpOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import isEmpty from 'lodash/isEmpty'
import { SearchBox } from './SearchBox'
import { SelectItem } from './SelectItem'
import { routeToWorkspaceEdit } from '../../Routes'
import styled from '@emotion/styled'
import { NoContentMessage } from './NoContentMessage'
import { useFiltered } from '../../hooks/useFiltered'
import { TextRegular, TextSmall } from '../base/TextStyle'
import { BoxPadding } from '../base/PaddingStyle'
import { SelectorContainer } from './SelectorContainer'
import { tryGet } from '../../utils/objectUtil'
import compact from 'lodash/compact'
import { workspaceOn } from '../base/WorkspaceName'
import { BranchEx } from '../../models/BranchEx'
import { capitalize } from '../../utils/textUtils'
import { useContext } from 'react'
import { BranchActionContext } from '../branch/useBranchActions'
import { WorkspaceActionContext } from '../workspace/WorkspaceActions'
import { useCachedWorkspace } from '../../hooks/useCachedWorkspace'
import { FlexRow } from '../base/Flex'
import { Ellipsis } from '../base/Ellipsis'
import { useLocalWorkspaces } from '../../desktop/hooks/useLocalWorkspaces'
import { IsDesktopApp } from '../../desktop/components/utils/IsDesktopApp'

type Props = {
  repoId: string
  selectedBranchId?: string
  selectedWorkspaceId?: string
  branchesById: Record<string, BranchEx>
  workspacesById: Record<string, Workspace>
  hideWorkspace?: boolean
  branchLink?: (branch: Branch) => string
  onBranchSelected?: (branch: Branch) => void
}

const SectionTitle = styled.div`
  ${TextRegular};
  ${BoxPadding};
  background-color: ${({ theme }) => theme.colors.blue.light};
  color: ${({ theme }) => theme.colors.black.primary};
  text-align: center;
`

const EllipsisDiv = styled.div`
  ${Ellipsis};
`

const WorkspaceTag = styled.div`
  ${TextSmall};
  font-weight: 300;
  border-radius: 0.8rem;
  padding: 0 0.3rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.white.primary};
`
const ActiveTag = styled(WorkspaceTag)`
  background-color: ${({ theme }) => theme.colors.red.dark};
  color: ${({ theme }) => theme.colors.red.contrastText};
`
const ClonedTag = styled(WorkspaceTag)`
  background-color: ${({ theme }) => theme.colors.warning.primary};
  color: ${({ theme }) => theme.colors.warning.contrastText};
`

type WorkspaceSubTitleProps = {
  workspace: Workspace
  isCloned?: boolean
  isActive: boolean
  branch?: Branch
}
const WorkspaceSubTitle = ({ workspace, branch, isActive, isCloned }: WorkspaceSubTitleProps) => {
  return (
    <FlexRow gap={0.5} centered={true}>
      <EllipsisDiv>{capitalize(workspaceOn(workspace, branch))}</EllipsisDiv>
      {isCloned && <ClonedTag>Cloned</ClonedTag>}
      {isCloned === undefined && <ClonedTag>N\A</ClonedTag>}
      {isActive && <ActiveTag>Active</ActiveTag>}
    </FlexRow>
  )
}

export const BranchSelector = ({
  repoId,
  selectedBranchId,
  selectedWorkspaceId,
  branchesById,
  workspacesById,
  hideWorkspace,
  branchLink,
  onBranchSelected,
}: Props) => {
  const { workspaceId: workspaceIdFromParam } = useUrlState()
  const { workspace: activeWorkspace } = useCachedWorkspace(repoId, workspaceIdFromParam)
  const { doAction: doBranchAction } = useContext(BranchActionContext)
  const { doAction: doWorkspaceAction } = useContext(WorkspaceActionContext)
  const { data: localWorkspacesRes } = useLocalWorkspaces()
  const [filteredBranches, searchBranches] = useFiltered(Object.values(branchesById), (b) => [
    b.branch_name,
    b.branch_id,
  ])
  const [filteredWorkspaces, searchWorkspaces] = useFiltered(Object.values(workspacesById), (ws) =>
    compact([ws.name, ws.branch_id, ws.source_commit, tryGet(branchesById, ws.branch_id)?.branch_name])
  )

  return (
    <SelectorContainer>
      <SearchBox
        hint={'Search for a branch or workspace'}
        onChange={(query) => {
          searchBranches(query)
          searchWorkspaces(query)
        }}
      />
      {!hideWorkspace && (
        <>
          <SectionTitle>Workspaces</SectionTitle>
          {isEmpty(filteredWorkspaces) ? (
            <NoContentMessage>No workspaces found</NoContentMessage>
          ) : (
            filteredWorkspaces.map((workspace) => (
              <SelectItem
                key={workspace.workspace_id}
                description={`Workspace ${workspace.workspace_id}`}
                redirectToOnClick={routeToWorkspaceEdit(repoId, workspace.workspace_id)}
                title={workspace.name}
                subTitles={[
                  <WorkspaceSubTitle
                    workspace={workspace}
                    branch={tryGet(branchesById, workspace.branch_id)}
                    isActive={workspace.workspace_id === activeWorkspace?.workspace_id}
                    isCloned={
                      IsDesktopApp() && localWorkspacesRes?.data?.some((w) => w.WorkspaceID === workspace.workspace_id)
                    }
                  />,
                ]}
                isSelected={selectedWorkspaceId === workspace.workspace_id}
                clickable
                menuActions={{
                  Rename: {
                    icon: <DriveFileRenameOutlineOutlinedIcon />,
                    action: () => doWorkspaceAction('rename', workspace),
                  },
                  Delete: { icon: <DeleteForeverOutlinedIcon />, action: () => doWorkspaceAction('delete', workspace) },
                }}
              />
            ))
          )}
        </>
      )}
      <SectionTitle>Branches</SectionTitle>
      {isEmpty(filteredBranches) ? (
        <NoContentMessage>No branches found</NoContentMessage>
      ) : (
        filteredBranches.map((branch) => {
          const currentBranch = activeWorkspace ? tryGet(branchesById, activeWorkspace.branch_id) : undefined

          let menuActions = {
            'Switch to branch': { icon: <MoveUpOutlined />, action: () => doBranchAction('checkout', branch) },
            Rename: { icon: <DriveFileRenameOutlineOutlinedIcon />, action: () => doBranchAction('rename', branch) },
            Delete: { icon: <DeleteForeverOutlinedIcon />, action: () => doBranchAction('delete', branch) },
          }

          if (activeWorkspace && currentBranch && currentBranch !== branch) {
            menuActions = {
              ...menuActions,
              [generateMergeFromBranchToText(branch, currentBranch)]: {
                icon: <RampLeftOutlinedIcon />,
                action: () => doBranchAction('merge', branch),
              },
              [generateMergeFromBranchToText(currentBranch, branch)]: {
                icon: <RampRightOutlinedIcon />,
                action: () => doBranchAction('merge-to', branch),
              },
            }
          }

          return (
            <SelectItem
              key={branch.branch_id}
              description={`Branch ${branch.branch_id}`}
              redirectToOnClick={branchLink && branchLink(branch)}
              onClick={onBranchSelected ? () => onBranchSelected(branch) : undefined}
              title={branch.branch_name}
              isSelected={selectedBranchId === branch.branch_id}
              clickable
              subTitles={branch.isDefault ? ['Default branch'] : undefined}
              menuActions={menuActions}
            />
          )
        })
      )}
    </SelectorContainer>
  )
}

const generateMergeFromBranchToText = (branch: Branch, targetBranch?: Branch) =>
  `Merge ${branch.branch_name} → ${targetBranch?.branch_name}`
