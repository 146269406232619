import WarningIcon from '@mui/icons-material/Warning'
import { CodeRef } from '../../components/base/CodeRef'
import { FlexRow } from '../../components/base/Flex'
import styled from '@emotion/styled'
import { TextTitle } from '../../components/base/TextStyle'
import { PrimaryButton } from '../../components/base/PrimaryButton'
import { execute } from '@todesktop/client-exec'
import config from '../../env/config'
import { useEffect, useState } from 'react'
import { sleep } from '../../utils/sleep'
import { DefaultService } from '../../api/agentapi'
import { log } from '../../utils/log'
import { infoToast } from '../../utils/toast'
import { Loader } from '../../components/base/Loader'

const Title = styled.span`
  ${TextTitle};
  white-space: pre-wrap;
`

export const AgentDownDescription = () =>
  config.IS_DEV_ENV ? <AgentDownDescriptionWithAgentButton /> : <AgentDownDescriptionTextOnly />

const CustomLoader = styled(Loader)`
  flex: 0;
`

const AgentDownDescriptionTextOnly = () => (
  <>
    <FlexRow gap={0.5} centered={true}>
      <WarningIcon />
      <Title>The local agent appears to be down, </Title>
    </FlexRow>
    <Title>
      please try to run <CodeRef>dv</CodeRef> on your command line to start it.
    </Title>
  </>
)

const AgentDownDescriptionWithAgentButton = () => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(false)
  }, [])

  return (
    <>
      {!loading && (
        <FlexRow gap={0.5} centered={true}>
          <WarningIcon />
          <Title>The local agent appears to be down,</Title>
          <Title>
            please run <CodeRef>dv</CodeRef> on your command line or
          </Title>
          <PrimaryButton
            disabled={false}
            onClick={async () => {
              setLoading(true)
              await execute([])
              await sleep(1500)
              try {
                await DefaultService.isAlive({})
                infoToast('Agent is up and running')
              } catch (e) {
                log.warn('Agent did not started with the execute', e)
              }
              setLoading(false)
            }}
          >
            Click Here
          </PrimaryButton>
        </FlexRow>
      )}
      {loading && (
        <FlexRow gap={1}>
          <CustomLoader size={21} />
          <Title>Starting local agent...</Title>
        </FlexRow>
      )}
    </>
  )
}
