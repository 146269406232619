import FileUploadIcon from '@mui/icons-material/FileUpload'
import { Styleable } from '../../../../theme'
import styled from '@emotion/styled'
import { useFilePicker } from 'use-file-picker'
import { Loader } from '../../../base/Loader'
import React, { useContext, useEffect, useState } from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { DirectoryPickerDialog } from '../../../dialogs/DirectoryPickerDialog'
import { useUrlState } from '../../../../hooks/useUrlState'
import { formatBytes, pluralize } from '../../../../utils/textUtils'
import { WorkspaceRevisionContext } from '../../../workspace/useWorkspaceRevisionUpdater'
import isEmpty from 'lodash/isEmpty'
import { useAnalytics } from '../../../../hooks/api/useAnalytics'
import { FilePath } from '../../../base/TextStyle'
import { Beforeunload } from 'react-beforeunload'
import { useFileShaWorker } from '../../../../hooks/useFileShaWorker'
import { useUploadFiles } from './useUploadFiles'
import { OptionsDropdown } from '../../../dropdown/OptionsDropdown'
import { getFirstParentPath } from '../../../../utils/pathUtils'
import { ProgressDialog } from '../../../dialogs/ProgressDialog'
import { Tooltip } from '@mui/material'
import { errorToast } from '../../../../utils/toast'
import { getFileManagerName } from '../../../../desktop/hooks/useOpenLocalWorkspaceItem'
import { WarningContainer } from '../../../base/WarningContainer'
import { FlexColumn } from '../../../base/Flex'

type Props = Styleable & {
  dragDroppedFiles: File[]
  clearDroppedFiles: () => void
}

const StyledFileUploadIcon = styled(FileUploadIcon)`
  cursor: pointer;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.blue.contrastText};
  background-color: ${({ theme }) => theme.colors.blue.primary};
  border-radius: 0.5rem;
  padding: ${({ theme }) => theme.padding.s}rem;
`

const LoaderWrapper = styled.div`
  cursor: pointer;
`

const ScrollableFileListView = styled.div`
  overflow-y: scroll;
  height: 10rem;
  width: 100%;
`

const diversionConfigPath = '.diversion'
export const WorkspaceFileUpload = ({ className, dragDroppedFiles, clearDroppedFiles }: Props) => {
  const postAnalytics = useAnalytics()
  const { repoId, workspaceId } = useUrlState()
  const fileShaWorker = useFileShaWorker()
  const { refresh: refreshWorkspaceRevision } = useContext(WorkspaceRevisionContext)
  const [uploading, setUploading] = useState(false)
  const [completedFilesCount, setCompletedFilesCount] = useState<number>(0)
  const [progressBytes, setProgressBytes] = useState<number>(0)
  const [progressOpen, setProgressOpen] = useState(false)
  const {
    openFilePicker,
    plainFiles: pickedFiles,
    loading,
  } = useFilePicker({
    onFilesSelected: ({ plainFiles, errors }) => {
      if (isEmpty(errors)) {
        postAnalytics('UploadFilesPicked', { files_count: plainFiles.length.toString() })
        setTargetPathDialogOpen(true)
      }
    },
    multiple: true,
    readFilesContent: false,
  })
  const largeFiles: File[] = []
  const fileSizeLimit = 5 * 1024 * 1024 * 1024
  const maxFilesToUpload = 1000

  function isForbiddenPath(file: File) {
    const relPath = file.webkitRelativePath || file.name
    relPath.split('/').forEach((dir) => {
      if (dir === diversionConfigPath) {
        return true
      }
    })
    return false
  }

  function isTooLarge(file: File) {
    return file.size > fileSizeLimit
  }

  const filesToUpload = (dragDroppedFiles.length > 0 ? dragDroppedFiles : pickedFiles).filter((file) => {
    if (isForbiddenPath(file)) {
      errorToast('Files under ' + diversionConfigPath + ' folder are not allowed to be uploaded.')
      return false
    }
    if (isTooLarge(file)) {
      errorToast(`File size is limited to ${formatBytes(fileSizeLimit)} per file, see list of skipped files.`)
      largeFiles.push(file)
      return false
    }
    return true
  })
  const [targetPathDialogOpen, setTargetPathDialogOpen] = useState(false)

  const uploadFilesCallback = useUploadFiles(
    repoId,
    workspaceId,
    filesToUpload,
    fileShaWorker,
    (loading) => {
      setUploading(loading)
      if (loading) {
        setCompletedFilesCount(0)
        setProgressBytes(0)
        setProgressOpen(true)
      }
    },
    clearDroppedFiles,
    refreshWorkspaceRevision,
    (bytes) => setProgressBytes((b) => b + bytes),
    () => setCompletedFilesCount((c) => c + 1)
  )

  useEffect(() => {
    if (!uploading) {
      setProgressOpen(false)
    }
  }, [uploading])

  useEffect(() => {
    if (dragDroppedFiles.length === 0) {
      return
    }
    setTargetPathDialogOpen(true)
  }, [dragDroppedFiles])

  const fileNames = filesToUpload.map((file) => file.name)
  const dirName = filesToUpload.every((file) => file.webkitRelativePath !== '')
    ? getFirstParentPath(filesToUpload[0]?.webkitRelativePath || '')
    : ''
  const totalSizeBytes = filesToUpload.reduce((acc, file) => acc + file.size, 0)
  const uploadNotAllowed = filesToUpload.length > maxFilesToUpload
  const title = uploadNotAllowed
    ? `Please use ${getFileManagerName()} to upload more than ${maxFilesToUpload} files.`
    : `Choose target directory to upload the ${dirName ? 'directory' : 'files'} to`
  return (
    <>
      <ProgressDialog
        title="Uploading"
        isOpen={progressOpen}
        setOpen={setProgressOpen}
        discreteProgress={{ total: filesToUpload.length, completed: completedFilesCount }}
        continuousProgress={{ total: totalSizeBytes, completed: progressBytes }}
      />
      {targetPathDialogOpen && (
        <DirectoryPickerDialog
          title={title}
          subTitle={
            uploadNotAllowed ? (
              ''
            ) : (
              <ScrollableFileListView>
                {largeFiles.length > 0 && (
                  <WarningContainer>
                    <WarningAmberIcon />
                    Skipping {pluralize(largeFiles.length, 'file')} due to size limit:
                  </WarningContainer>
                )}
                {largeFiles.map((file) => (
                  <div key={file.name}>
                    <FlexColumn gap={0.5}>
                      <FilePath>{file.name}</FilePath>
                    </FlexColumn>
                  </div>
                ))}
                {filesToUpload.length > 30 && (
                  <div style={{ marginTop: '1rem' }}>
                    Tip: Uploading a large amount of files is faster using {getFileManagerName()}
                  </div>
                )}
                {pluralize(fileNames.length, 'file')} will be uploaded{dirName ? ' from 1 directory' : ''}, totaling{' '}
                {formatBytes(totalSizeBytes)}:
                {(dirName ? [dirName] : fileNames).map((fileName) => (
                  <div key={fileName}>
                    <FlexColumn gap={0.5}>
                      <FilePath>{fileName}</FilePath>
                    </FlexColumn>
                  </div>
                ))}
              </ScrollableFileListView>
            )
          }
          treeIdSuffix="upload"
          buttonLabel="Upload"
          primaryButtonTooltip={
            uploadNotAllowed ? `Please use ${getFileManagerName()} to upload more than ${maxFilesToUpload} files.` : ''
          }
          disabled={uploadNotAllowed}
          isOpen={targetPathDialogOpen}
          handleClose={() => {
            clearDroppedFiles()
            setTargetPathDialogOpen(false)
          }}
          onSelected={(path) => {
            setTargetPathDialogOpen(false)
            uploadFilesCallback(path)
          }}
        />
      )}
      {(loading || uploading) && <Beforeunload onBeforeunload={(event) => event.preventDefault()} />}
      <div className={className}>
        {loading || uploading ? (
          <Tooltip title="Uploading files, click for details" arrow>
            <LoaderWrapper onClick={() => uploading && setProgressOpen(true)}>
              <Loader size={18} />
            </LoaderWrapper>
          </Tooltip>
        ) : (
          <OptionsDropdown
            button={<StyledFileUploadIcon />}
            items={[
              {
                key: 'files',
                title: 'Upload file(s)',
                onSelected: () => {
                  postAnalytics('UploadFilesButtonClicked', {})
                  openFilePicker()
                },
              },
              {
                key: 'dirs',
                title: 'Upload folder',
                onSelected: () => {
                  postAnalytics('UploadFilesButtonClicked', {})
                  openFilePicker({ directory: true })
                },
              },
            ]}
          />
        )}
      </div>
    </>
  )
}
