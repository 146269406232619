import { log } from '../../../utils/log'
import { DefaultService } from '../../../api/agentapi'
import { IsDesktopApp } from './IsDesktopApp'

export const initiateAgentCredentials = async (refreshToken: string) => {
  if (!IsDesktopApp()) {
    log.info('trying to initiate agent credentials, not in desktop app context')
    return
  }

  try {
    await DefaultService.isAlive({})
  } catch (e) {
    log.warn('Cannot initiate credentials, agent is down', e)
    return
  }

  try {
    DefaultService.updateCoreCredentials({ requestBody: { RefreshToken: refreshToken } })
  } catch (e) {
    log.error('Error in initiating agent credentials', e)
    return
  }
}
