import { ActionDialog } from '../dialogs/ActionDialog'
import { BranchEx } from '../../models/BranchEx'
import React, { useEffect } from 'react'
import { useUrlState } from '../../hooks/useUrlState'
import { useBranch } from '../../hooks/api/useBranch'
import { useMergeAsync } from './MergeDialog'
import { validateWorkSpace } from '../../hooks/useWorkspaceValidation'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { useCachedWorkspace } from '../../hooks/useCachedWorkspace'

type Props = {
  isOpen: boolean
  setOpen: (open: boolean) => void
  branch: BranchEx | undefined
}

export const MergeToDialog = ({ isOpen, setOpen, branch }: Props) => {
  const { repoId, workspaceId: workspaceIdFromParam } = useUrlState()
  const { workspace } = useCachedWorkspace(repoId, workspaceIdFromParam)
  const workspaceId = workspace?.workspace_id
  const { branch: mergeFromBranch } = useBranch(repoId, workspace?.branch_id)
  const postAnalytics = useAnalytics()
  const mergeAsync = useMergeAsync()
  const { refresh: targetBranchRefresh } = useBranch(repoId, branch?.branch_id)

  useEffect(() => validateWorkSpace(isOpen, workspaceId, setOpen, workspace), [isOpen, setOpen, workspace, workspaceId])
  return (
    <ActionDialog
      title="Merge into"
      isOpen={isOpen}
      onConfirmAsync={async () => {
        postAnalytics('MergeToDialogConfirmed', {})
        await mergeAsync(repoId!, mergeFromBranch?.branch_id!, branch?.branch_id!)
        await targetBranchRefresh()
      }}
      message={`About to merge branch ${mergeFromBranch?.branch_name} into ${branch?.branch_name}`}
      setOpen={setOpen}
      confirmButtonLabel="Merge"
      loadingMessage="Merging branches..."
    />
  )
}
