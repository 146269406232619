import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const FlexFiller = styled.div`
  flex: 1;
  -webkit-box-flex: 1;
`

export const FlexRowStyle = css`
  display: flex;
  flex-direction: row;
`

export const FlexRow = styled.div<{ gap?: number; centered?: boolean; centerContent?: boolean }>`
  ${FlexRowStyle};
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}rem;
    `}
  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
    `}
  ${({ centerContent }) =>
    centerContent &&
    css`
      justify-content: center;
    `}
`

export const FlexColumnStyle = css`
  display: flex;
  flex-direction: column;
`

export const FlexColumn = styled.div<{ gap?: number; centered?: boolean }>`
  ${FlexColumnStyle};
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap}rem;
    `}
  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
    `}
`
