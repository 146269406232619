import isString from 'lodash/isString'
import { FileShaResult } from '../workers/fileShaWorkerUtils'
import { log } from '../utils/log'

export type CalculateFileSha = (file: File) => Promise<string>

export const useFileShaWorker = (): CalculateFileSha => {
  return async (file: File) => {
    const worker = new Worker(new URL('../workers/fileShaWorker.ts', import.meta.url))
    try {
      return await new Promise((resolve) => {
        worker.postMessage(file)
        worker.onmessage = (e) => {
          const shaResult = e.data as FileShaResult
          if (!!shaResult.error) {
            log.error('Error while trying to calculate sha in worker', shaResult.error)
            throw shaResult.error
          }
          if (!isString(shaResult.sha)) {
            throw new Error('Expected string from worker', shaResult.sha)
          }
          resolve(shaResult.sha)
        }
      })
    } finally {
      worker.terminate()
    }
  }
}
