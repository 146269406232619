import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'

type Props = {
  value: string | null
  setCommitMessage: (message: string) => void
}

const StyledTextarea = styled.textarea`
  margin: 1rem;
  padding: ${({ theme }) => theme.padding.m}rem;
  background-color: ${({ theme }) => theme.colors.blue.light};
  color: ${({ theme }) => theme.colors.black.primary};
  outline: 0 none;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  resize: none;
  width: calc(100% - 2rem);
  min-height: 6rem;
  flex-grow: 1;
`

export const CommitMessage = ({ setCommitMessage, value }: Props) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  useEffect(() => {
    if (ref.current && ref.current.value !== value) {
      ref.current.value = value || ''
    }
  }, [value])
  return (
    <StyledTextarea
      ref={ref}
      autoFocus={Boolean(value)}
      placeholder="Commit message"
      rows={3}
      defaultValue={value || undefined}
      onChange={(event) => {
        event.preventDefault()
        event.stopPropagation()
        setCommitMessage(event.target.value)
      }}
    />
  )
}
