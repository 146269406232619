import { UserAvatar } from './UserAvatar'
import { FlexColumn, FlexRow } from './Flex'
import React from 'react'
import styled from '@emotion/styled'
import { TextSmall } from './TextStyle'
import { Styleable } from '../../theme'

type Props = Styleable & {
  picture?: string
  name?: string
  email?: string
}

const UserRow = styled(FlexRow)`
  padding: ${({ theme }) => theme.padding.l}rem 0;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-content: start;
`

const UserEmail = styled.div`
  color: ${({ theme }) => theme.colors.black.secondary};
  ${TextSmall};
`

export const UserCard = ({ className, picture, name, email }: Props) => {
  return (
    <UserRow className={className}>
      <UserAvatar picture={picture} name={name} email={email || ''} hideTooltip />
      <FlexColumn>
        <div>{name || email}</div>
        {email && name && <UserEmail>{email}</UserEmail>}
      </FlexColumn>
    </UserRow>
  )
}
