import { useRef, useState } from 'react'
import styled from '@emotion/styled'
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import { CommitDialog } from './CommitDialog'
import { PrimaryButton } from '../base/PrimaryButton'
import { Styleable } from '../../theme'
import { useKeyPress } from '../../hooks/useKeyPress'
import { isFocused } from '../../utils/focus'
import * as KeyCode from 'keycode-js'
import {FlexRow} from "../base/Flex";

const StyledButton = styled(PrimaryButton)`
  margin-top: 0.75rem;
`

type Props = Styleable & {
  enabled: boolean
  postCommitAsync: () => Promise<void>
  mergeIntoWorkspace?: boolean
}

const getTitle = (mergeIntoWorkspace: boolean, enabled: boolean) => {
  if (mergeIntoWorkspace) {
    return enabled ? 'Apply changes' : 'Please make sure there are items selected and your agent finished uploading'
  }
  return enabled
    ? 'Commit changes'
    : 'Please make sure there are items selected, your agent finished uploading and you entered a commit message'
}

export const CommitButton = ({ className, enabled, postCommitAsync, mergeIntoWorkspace }: Props) => {
  const [dialogIsOpen, setDialogIsOpen] = useState(false)
  const buttonRef = useRef<HTMLDivElement>(null)
  useKeyPress(
    (event: KeyboardEvent) =>
      event.key === KeyCode.CODE_ENTER && (event.ctrlKey || event.metaKey || isFocused(buttonRef.current)),
    (event: KeyboardEvent) => setDialogIsOpen(true),
    enabled
  )
  return (
    <>
      <CommitDialog
        isOpen={dialogIsOpen}
        setOpen={setDialogIsOpen}
        postCommitAsync={postCommitAsync}
        mergeIntoWorkspace={Boolean(mergeIntoWorkspace)}
      />
      <StyledButton
        ref={buttonRef}
        title={getTitle(Boolean(mergeIntoWorkspace), enabled)}
        className={className}
        disabled={!enabled}
        onClick={() => {
          enabled && setDialogIsOpen(true)
        }}
        tabIndex={enabled ? 0 : undefined}
      >
          <FlexRow gap={0.5} centered={true}>
          <AssignmentTurnedInOutlinedIcon />
          {mergeIntoWorkspace ? 'Apply' : 'Commit'}
        </FlexRow>
      </StyledButton>
    </>
  )
}
