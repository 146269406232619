import { AuthColumn, AuthPage } from './Common'
import { rawNavigate } from '../../utils/navigateRaw'
import React, { useEffect } from 'react'
import styled from '@emotion/styled'

import { TextRegular, TextTitle } from '../base/TextStyle'
import { IsDesktopApp } from '../../desktop/components/utils/IsDesktopApp'
import { useNavigate } from 'react-router'

const TitleText = styled.div`
  ${TextTitle};
  color: ${({ theme }) => theme.colors.black.primary};
  text-align: center;
  width: 100%;
`

export const StyledLink = styled.span`
  ${TextRegular};
  cursor: pointer;
  text-align: center;
  width: 100%;
`

export const DesktopRedirectComponent = ({
  webAppRedirect,
  desktopAppRedirect,
}: {
  webAppRedirect: string
  desktopAppRedirect: string
}) => {
  const navigate = useNavigate()
  useEffect(() => {
    if (!IsDesktopApp()) {
      rawNavigate(webAppRedirect)
    }
  })

  if (IsDesktopApp()) {
    navigate(desktopAppRedirect)
    return null
  }

  return (
    <AuthPage>
      <AuthColumn>
        <TitleText>Redirecting to your Diversion app...</TitleText>
        <StyledLink onClick={() => rawNavigate(webAppRedirect)}>If you weren't redirected, click here.</StyledLink>
      </AuthColumn>
    </AuthPage>
  )
}
