import styled from '@emotion/styled'
import SearchIcon from '@mui/icons-material/Search'
import { useSessionStorage } from 'usehooks-ts'
import { useEffect, useRef, useState } from 'react'
import { Styleable } from '../../theme'
import ClearIcon from '@mui/icons-material/Clear'
import { CircularProgress } from '@mui/material'
import { BoxPadding } from '../base/PaddingStyle'

const StyledTextField = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem;
  ${BoxPadding};
  height: fit-content;
  border: 1px solid ${({ theme }) => theme.colors.stroke};
  background-color: ${({ theme }) => theme.colors.blue.light};
  color: ${({ theme }) => theme.colors.black.secondary};
  border-radius: 1rem;
`

const StyledInput = styled.input`
  background-color: inherit;
  padding-left: 1rem;
  outline: 0 none;
  width: 100%;
`

const ClearButton = styled.div`
  cursor: pointer;
  opacity: 80%;

  svg {
    width: 20px;
  }

  :hover {
    opacity: 1;
  }
`

const SmallLoader = styled(CircularProgress)`
  color: ${({ theme }) => theme.colors.black.secondary};
  float: right;
  position: relative;
  bottom: 30px;
  right: 24px;
`

type Props = Styleable & {
  hint: string
  onChange: (query: string) => void
  isLoading?: boolean
  noPersistSearch?: boolean
}

export const SearchBox = ({ hint, onChange, isLoading, className, noPersistSearch }: Props) => {
  const [persistedQuery, setPersistedQuery] = useSessionStorage<string | undefined>(`search.${hint}`, undefined)
  const [stateQuery, setStateQuery] = useState<string | undefined>()
  const [query, setQuery] = noPersistSearch ? [stateQuery, setStateQuery] : [persistedQuery, setPersistedQuery]

  const ref = useRef<HTMLInputElement>(null)
  useEffect(() => {
    query && onChange(query)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div>
      <StyledTextField className={className}>
        <SearchIcon />
        <StyledInput
          autoFocus
          ref={ref}
          type="text"
          placeholder={hint}
          defaultValue={query}
          onChange={(event) => {
            const { value } = event.target
            setQuery(value)
            onChange(value)
          }}
        />
        {!isLoading && (
          <ClearButton
            title="Clear"
            onClick={() => {
              setQuery(undefined)
              onChange('')
              if (ref.current) {
                ref.current.value = ''
              }
            }}
          >
            <ClearIcon />
          </ClearButton>
        )}
      </StyledTextField>
      {isLoading && <SmallLoader size={20} />}
    </div>
  )
}
