/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlobUploadUrl } from '../models/BlobUploadUrl';
import type { Error } from '../models/Error';
import type { FileMode } from '../models/FileMode';
import type { StorageBackend } from '../models/StorageBackend';
import type { StorageUri } from '../models/StorageUri';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FileMutationService {
    /**
     * Upload file to a new or existing path
     * @returns Error An error occurred
     * @returns any File created or updated
     * @throws ApiError
     */
    public static srcHandlersv2FilesCreateOrUpdateFile({
        repoId,
        refId,
        path,
        xDvClientId,
        mode,
        mtime,
        storageBackend,
        storageUri,
        size,
        sha1,
        requestBody,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a workspace, branch or commit.
         */
        refId: string,
        /**
         * A path to an item inside the repository.
         */
        path: string,
        /**
         * The unique id of the client making this request
         */
        xDvClientId: string,
        /**
         * The file mode (as Unix mode)
         */
        mode: FileMode,
        /**
         * The file's modification time in seconds since epoch
         */
        mtime: number,
        /**
         * An optional storage type for async upload.
         */
        storageBackend?: StorageBackend,
        /**
         * An optional storage uri to be sent along storage_backend.
         */
        storageUri?: StorageUri,
        /**
         * Blob size in bytes
         */
        size?: number,
        /**
         * A sha1 hexdigest
         */
        sha1?: string,
        /**
         * Blob binary contents, if the file is a blob and if alternative storage solution was not used.
         */
        requestBody?: Blob,
    }): CancelablePromise<Error | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/repos/{repo_id}/files/{ref_id}/{path}',
            path: {
                'repo_id': repoId,
                'ref_id': refId,
                'path': path,
            },
            headers: {
                'X-Dv-Client-Id': xDvClientId,
            },
            query: {
                'storage_backend': storageBackend,
                'storage_uri': storageUri,
                'mode': mode,
                'mtime': mtime,
                'size': size,
                'sha1': sha1,
            },
            body: requestBody,
            mediaType: 'application/octet-stream',
            errors: {
                400: `Path should be normalized`,
                412: `Race condition, please attempt the operation again`,
            },
        });
    }
    /**
     * Delete a file from an existing path
     * @returns Error An error occurred
     * @returns any File deleted
     * @throws ApiError
     */
    public static srcHandlersv2FilesDeleteFile({
        repoId,
        refId,
        path,
        xDvClientId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a workspace, branch or commit.
         */
        refId: string,
        /**
         * A path to an item inside the repository.
         */
        path: string,
        /**
         * The unique id of the client making this request
         */
        xDvClientId: string,
    }): CancelablePromise<Error | any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/repos/{repo_id}/files/{ref_id}/{path}',
            path: {
                'repo_id': repoId,
                'ref_id': refId,
                'path': path,
            },
            headers: {
                'X-Dv-Client-Id': xDvClientId,
            },
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Move a file to a different path
     * @returns Error An error occurred
     * @returns any File moved
     * @throws ApiError
     */
    public static srcHandlersv2FilesMoveFile({
        repoId,
        refId,
        path,
        newPath,
        xDvClientId,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * An ID of a workspace, branch or commit.
         */
        refId: string,
        /**
         * A path to an item inside the repository.
         */
        path: string,
        /**
         * The new path to where the file has moved
         */
        newPath: string,
        /**
         * The unique id of the client making this request
         */
        xDvClientId: string,
    }): CancelablePromise<Error | any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/repos/{repo_id}/files/{ref_id}/{path}',
            path: {
                'repo_id': repoId,
                'ref_id': refId,
                'path': path,
            },
            headers: {
                'X-Dv-Client-Id': xDvClientId,
            },
            query: {
                'new_path': newPath,
            },
            errors: {
                404: `The specified resource was not found`,
            },
        });
    }
    /**
     * Request an upload link for async blob upload from client side
     * @returns Error An error occurred
     * @returns BlobUploadUrl Upload URL is returned
     * @throws ApiError
     */
    public static srcHandlersv2FilesGetBlobUploadLink({
        repoId,
        sha1,
    }: {
        /**
         * The repo ID of the repository. Repo _name_ can be used instead of the ID, but usage of ID for permanent linking and API requests is preferred.
         */
        repoId: string,
        /**
         * A sha1 hexdigest
         */
        sha1?: string,
    }): CancelablePromise<Error | BlobUploadUrl> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/repos/{repo_id}/blobs/upload',
            path: {
                'repo_id': repoId,
            },
            query: {
                'sha1': sha1,
            },
        });
    }
}
