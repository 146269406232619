import styled from '@emotion/styled'
import { TextTitle } from '../base/TextStyle'
import { Separator } from '../base/Separator'
import { useCommits } from '../../hooks/api/useCommits'
import { useUrlState } from '../../hooks/useUrlState'
import { Loader } from '../base/Loader'
import { useBranchesById } from '../../hooks/api/useBranches'
import { CommitCard } from '../commitTree/CommitCard'
import { useCopyToClipboardAction } from '../../hooks/useCopyToClipboardAction'
import { useTagsByCommitId } from '../../hooks/api/useTagsByCommitId'
import { css } from '@emotion/react'
import { useWorkspace } from '../../hooks/api/useWorkspace'
import { FlexColumnStyle, FlexRow } from '../base/Flex'

const LATEST_COUNT = 5

const Container = styled.div`
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.black.primary};
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const Card = styled.div`
  ${FlexColumnStyle};
  background: ${({ theme }) => theme.colors.card.primary};
  border: ${({ theme }) => theme.colors.stroke} 1px solid;
  padding: ${({ theme }) => theme.padding.xl}rem;
  height: fit-content;
  min-height: 400px;
  width: fit-content;
  min-width: 600px;
  border-radius: 3rem;
`

const ContentCard = styled.div<{ allowOverflow: boolean }>`
  ${FlexColumnStyle};
  height: 100%;
  width: 100%;
  flex-grow: 1;
  gap: 1.6rem;
  ${({ allowOverflow }) =>
    allowOverflow &&
    css`
      overflow-y: auto;
    `};
`

const CardTitle = styled.div`
  ${TextTitle};
  align-self: flex-start;
`

const StyledCommitCard = styled(CommitCard)`
  width: 46rem;
`

const LoaderWrapper = styled(FlexRow)`
  flex-grow: 1;
`

export const LatestCommits = () => {
  const { repoId, branchId, workspaceId } = useUrlState()
  const { workspace } = useWorkspace(repoId, workspaceId)
  const refId = workspace?.branch_id || workspace?.base_commit_id || branchId
  const { data: commits, loading: commitsLoading } = useCommits(repoId!!, refId, LATEST_COUNT)
  const { tagsByCommitId, loading: tagsLoading } = useTagsByCommitId(repoId!!)
  const copyToClipboard = useCopyToClipboardAction()
  const { branchesById, loading: branchesLoading } = useBranchesById(repoId!!)
  const loading = commitsLoading || branchesLoading || tagsLoading
  return (
    <Container>
      <Card>
        <ContentCard allowOverflow={!loading}>
          <CardTitle>Latest Commits</CardTitle>
          <Separator />
          {loading ? (
            <LoaderWrapper centered={true}>
              <Loader />
            </LoaderWrapper>
          ) : (
            (commits || []).map((commit) => (
              <StyledCommitCard
                key={commit.commit_id}
                repoId={repoId!!}
                commit={commit}
                tags={tagsByCommitId[commit.commit_id]}
                branch={branchesById[commit.branch_id]}
                copyToClipboard={copyToClipboard}
                linkeable
                highlight={false}
                showUserImage
              />
            ))
          )}
        </ContentCard>
      </Card>
    </Container>
  )
}
