import { Outlet } from 'react-router'
import { Header } from '../header/Header'
import styled from '@emotion/styled'
import { FlexColumn } from '../base/Flex'
import { BranchActionContext, useBranchActions } from '../branch/useBranchActions'
import { useWorkspaceActions, WorkspaceActionContext } from '../workspace/WorkspaceActions'
import { CommitActionContext, useCommitActions } from '../commit/CommitActions'

type Props = {
  repoImporting?: boolean
}

const Container = styled(FlexColumn)`
  height: 100vh;
  width: 100vw;
  position: fixed;
`

const Content = styled.div`
  display: flex;
  flex: 1 1 0;
  height: calc(100vh - 4rem);
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`

const ImportingMessage = styled.div`
  text-align: center;
  width: 100%;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.blue.primary};
  margin-top: ${({ theme }) => theme.padding.xl}rem;
`

export const BrowseRoute = ({ repoImporting }: Props) => {
  const { doAction: doBranchAction, dialogs: BranchActionDialogs } = useBranchActions()
  const { doAction: doWorkspaceAction, dialogs: WorkspaceActionDialogs } = useWorkspaceActions()
  const { doAction: doCommitAction, dialogs: CommitActionDialogs } = useCommitActions()

  return (
    <>
      <BranchActionDialogs />
      <WorkspaceActionDialogs />
      <CommitActionDialogs />
      <BranchActionContext.Provider value={{ doAction: doBranchAction }}>
        <WorkspaceActionContext.Provider value={{ doAction: doWorkspaceAction }}>
          <CommitActionContext.Provider value={{ doAction: doCommitAction }}>
            <Container>
              <Header repoImporting={repoImporting} />
              <Content>
                {repoImporting ? (
                  <ImportingMessage>
                    Repository is being imported. You will be notified once import is completed.
                  </ImportingMessage>
                ) : (
                  <Outlet />
                )}
              </Content>
            </Container>
          </CommitActionContext.Provider>
        </WorkspaceActionContext.Provider>
      </BranchActionContext.Provider>
    </>
  )
}
