import { IntroPage, IntroTitle } from './IntroPage'
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from 'react'
import { ClonePage } from './subpages/ClonePage'
import { DownloadPage } from './subpages/DownloadPage'
import { FinalPage } from './subpages/FinalPage'
import { PrimaryButton } from '../base/PrimaryButton'
import styled from '@emotion/styled'
import { FlexRowStyle } from '../base/Flex'
import { pageQueryParam, routeToDashboard } from '../../Routes'
import { useNavigate } from 'react-router'
import { css } from '@emotion/react'
import { useAnalytics } from '../../hooks/api/useAnalytics'
import { useSearchParams } from 'react-router-dom'
import isNil from 'lodash/isNil'
import { log } from '../../utils/log'

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`

const StyledIntroPage = styled(IntroPage)`
  min-height: 480px;
`

const MainTitle = styled(IntroTitle)`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black.primary};
`

const ButtonsRow = styled.div`
  ${FlexRowStyle};
  gap: 1rem;

  div {
    width: 6rem;
  }
`

const SubPageWrapper = styled.div`
  height: fit-content;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue.light};
  padding: ${({ theme }) => theme.padding.l}rem;
  border-radius: 0.5rem;

  > *:last-child {
    margin-bottom: 1rem;
  }
`

const StyledButton = styled(PrimaryButton)<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0;
      cursor: default;
      pointer-events: none;
      user-select: none;
    `}
`

export const WelcomeRoute = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [subPage, setSubPage] = useState<number>(0)
  const postAnalytics = useAnalytics()

  const SubPages: Record<string, ReactElement> = useMemo(() => ({
      downloadCli: <DownloadPage/>,
      cloneRepo: <ClonePage/>,
      finalPage: <FinalPage/>,
  }), [])
  const subPagesLength = Object.values(SubPages).length

  useEffect(() => {
    const page = searchParams.get(pageQueryParam)
    log.info('WelcomeRoute', { page })
    if (!isNil(page)) {
      const subPage = parseInt(page!)
      if (subPage >= 0 && subPage < subPagesLength) {
        setSubPage(subPage)
      }
      setSearchParams({}, { replace: true })
    }
  }, [searchParams, setSearchParams, subPagesLength])

  useEffect(() => {
    postAnalytics('WelcomePageLoaded', { welcome_page_sub_page: Object.keys(SubPages)[subPage] })
  }, [SubPages, subPage, postAnalytics])

  const setSubPageWithAnalytics = useCallback(
    (delta: number) =>
      setSubPage((current) => {
        postAnalytics('WelcomePageNavigation', {
          welcome_page_sub_page: Object.keys(SubPages)[current],
          welcome_page_target_sub_page: Object.keys(SubPages)[current + delta],
        })
        return current + delta
      }),
    [SubPages, postAnalytics]
  )

  return (
    <Container>
      <StyledIntroPage>
        <MainTitle>Welcome to Diversion</MainTitle>
        <SubPageWrapper>{Object.values(SubPages)[subPage]}</SubPageWrapper>
        <ButtonsRow>
          {subPage !== 0 && (
            <StyledButton disabled={subPage === 0} onClick={() => setSubPageWithAnalytics(-1)}>
              Previous
            </StyledButton>
          )}
          <StyledButton disabled={subPage === subPagesLength - 1} onClick={() => setSubPageWithAnalytics(1)}>
            Next
          </StyledButton>
          {subPage === subPagesLength - 1 && (
            <StyledButton disabled={false} onClick={() => navigate(routeToDashboard())}>
              Finish
            </StyledButton>
          )}
        </ButtonsRow>
      </StyledIntroPage>
    </Container>
  )
}
