import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import React, { ReactNode } from 'react'
import { generatePath } from 'react-router'
import { BrowseRoute } from './components/browse/BrowseRoute'
import { WorkspaceRoute } from './components/workspace/WorkspaceRoute'
import { WorkspaceEditRoute } from './components/workspace/WorkspaceEditRoute'
import { WorkspaceFileViewerRoute } from './components/workspace/WorkspaceFileViewerRoute'
import { CommitHistoryRoute } from './components/commit/CommitHistoryRoute'
import { CommitTreeRoute } from './components/commitTree/CommitTreeRoute'
import { CommitFileViewerRoute } from './components/commit/CommitFileViewerRoute'
import { CommitCompareSidePanelRoute } from './components/commit/CommitCompareSidePanelRoute'
import { MergesRoute } from './components/merge/MergesRoute'
import { MergesSidePanelRoute } from './components/merge/MergesSidePanelRoute'
import { MergesConflictsRoute } from './components/merge/MergesConflictsRoute'
import { MergesConflictViewerRoute } from './components/merge/MergesConflictViewerRoute'
import { DetachedCommitRoute } from './components/commit/DetachedCommitRoute'
import { WelcomeRoute } from './components/welcome/WelcomeRoute'
import { PendingRoute } from './components/welcome/PendingRoute'
import { LatestCommits } from './components/commit/LatestCommits'
import { WorkspaceSettingsRoute } from './components/workspace/WorkspaceSettingsRoute'
import { DashboardRoute } from './components/dashboard/DashboardRoute'
import { RepoRoute } from './components/browse/RepoRoute'
import { TitleSetter } from './components/TitleSetter'
import isEmpty from 'lodash/isEmpty'
import { FileHistoryRoute } from './components/history/FileHistoryRoute'
import { SignInPage } from './components/auth/SignInPage'
import { PasswordResetPage } from './components/auth/PasswordResetPage'
import { SignupVerifyPage } from './components/auth/SignupVerifyPage'
import { SignUpPage } from './components/auth/SignUpPage'
import { PasswordResetVerifyPage } from './components/auth/PasswordResetVerifyPage'
import { IntegrationsRoute } from './components/integrations/IntegrationsRoute'
import { TokensTab } from './components/integrations/TokensTab'
import { WebhooksTab } from './components/integrations/WebhooksTab'
import BranchGraph from './components/branchVisualizer/BranchGraph'
import { FullPageLoader } from './components/base/Loader'
import { DesktopLoginPage } from './components/auth/DesktopLoginPage'
import { DesktopLogoutPage } from './components/auth/DesktopLogoutPage'

export const usernameQueryParam = 'username'

export const emailQueryParam = 'email'

export const verifyCodeQueryParam = 'verifycode'

export const pageQueryParam = 'page'

enum RootPaths {
  Dashboard = 'dashboard',
  Repo = 'repo/:repoId',
  Welcome = 'welcome',
  Pending = 'pending',
  Integrations = 'integrations',
  Billing = 'billing',
}

enum AuthPaths {
  SignIn = 'signin',
  SignUp = 'signup',
  SignUpVerify = SignUp + '/verify',
  PasswordReset = SignIn + '/forgotPassword',
  PasswordResetVerify = PasswordReset + '/verify',
  DesktopLogin = 'desktopOauth2Login',
  DesktopLogout = 'desktopOauth2Logout',
}

enum RepoSubPaths {
  RepoMerges = 'merges',
  RepoBranch = 'branch/:branchId',
  RepoCommit = 'commit/:commitId',
  Workspace = 'workspace/:workspaceId',
  FileHistory = 'files/history',
  visualize = 'visualize',
}

enum MergesSubPaths {
  Merge = ':mergeId',
}

enum MergeSubPath {
  Conflict = ':conflictId',
}

enum BranchSubPaths {
  View = 'view',
}

enum WorkspaceSubPaths {
  View = 'view',
  Edit = 'edit',
  Settings = 'settings',
  Visualize = 'visualize',
}

enum RefViewSubPaths {
  Commit = ':commitId',
}

enum CommitSubPaths {
  View = 'view',
  Compare = 'compare',
}

enum CommitCompareToSubPaths {
  CompareToCommit = ':compareCommitId',
}

enum RefSubPaths {
  FilePath = '*',
}

enum IntegrationsPaths {
  Tokens = 'tokens',
  Webhooks = 'webhooks',
}

type RoutePaths =
  | RootPaths
  | RepoSubPaths
  | MergesSubPaths
  | MergeSubPath
  | RefSubPaths
  | BranchSubPaths
  | RefViewSubPaths
  | CommitSubPaths
  | CommitCompareToSubPaths
  | IntegrationsPaths

declare module 'react-router' {
  export interface PathRouteProps {
    // @ts-ignore
    path: RoutePaths | ''
    element?: ReactNode
    children?: ReactNode
  }
}

export const routeToWelcome = (page?: number) =>
  generatePath(`/${RootPaths.Welcome + (page ? `?${pageQueryParam}=${page}` : '')}`)

export const routeToDashboard = () => generatePath(`/${RootPaths.Dashboard}`)

export const routeToPending = () => generatePath(`/${RootPaths.Pending}`)

export const routeToApiTokens = () => generatePath(`/${RootPaths.Integrations}/${IntegrationsPaths.Tokens}`)

export const routeToWebhooks = () => generatePath(`/${RootPaths.Integrations}/${IntegrationsPaths.Webhooks}`)

export const routeToRepo = (repoId: string) => generatePath(`/${RootPaths.Repo}`, { repoId })

export const routeToWorkspaceSettings = (repoId: string, workspaceId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.Workspace}/${WorkspaceSubPaths.Settings}`, { repoId, workspaceId })

export const routeToWorkspaceEdit = (repoId: string, workspaceId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.Workspace}/${WorkspaceSubPaths.Edit}`, { repoId, workspaceId })

export const routeToWorkspaceView = (repoId: string, workspaceId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.Workspace}/${WorkspaceSubPaths.View}`, { repoId, workspaceId })

export const routeToWorkspaceBranchGraphView = (repoId: string, workspaceId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.Workspace}/${WorkspaceSubPaths.Visualize}`, { repoId, workspaceId })

export const routeToWorkspaceCommitView = (repoId: string, workspaceId: string, commitId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.Workspace}/${WorkspaceSubPaths.View}/${RefViewSubPaths.Commit}`, {
    repoId,
    workspaceId,
    commitId,
  })

export const routeToBranchView = (repoId: string, branchId: string, commitId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoBranch}/${BranchSubPaths.View}/${RefViewSubPaths.Commit}`, {
    repoId,
    branchId,
    commitId,
  })

export const routeToCommitView = (repoId: string, commitId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoCommit}/${CommitSubPaths.View}`, {
    repoId,
    commitId,
  })

export const routeToCommitCompare = (repoId: string, commitId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoCommit}/${CommitSubPaths.Compare}`, {
    repoId,
    commitId,
  })

export const routeToCommitCompareWithCommit = (repoId: string, commitId: string, compareCommitId: string) =>
  generatePath(
    `/${RootPaths.Repo}/${RepoSubPaths.RepoCommit}/${CommitSubPaths.Compare}/${CommitCompareToSubPaths.CompareToCommit}`,
    {
      repoId,
      commitId,
      compareCommitId,
    }
  )

export const routeToMerges = (repoId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoMerges}`, { repoId })

export const routeToMerge = (repoId: string, mergeId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoMerges}/${MergesSubPaths.Merge}`, { repoId, mergeId })

export const routeToMergeConflict = (repoId: string, mergeId: string, conflictId: string) =>
  generatePath(`/${RootPaths.Repo}/${RepoSubPaths.RepoMerges}/${MergesSubPaths.Merge}/${MergeSubPath.Conflict}`, {
    repoId,
    mergeId,
    conflictId,
  })

export const routeToFileHistory = (repoId: string, filePath: string, branchId?: string, workspaceId?: string) =>
  withQueryParams(
    appendFilePath(generatePath(`/${RootPaths.Repo}/${RepoSubPaths.FileHistory}`, { repoId }), filePath),
    { ...(branchId ? { branchId } : {}), ...(workspaceId ? { workspaceId } : {}) }
  )

export const routeToSignIn = () => generatePath(`/${AuthPaths.SignIn}`)
export const routeToBilling = () => generatePath(`/${RootPaths.Billing}`)

export const routeToSignInPasswordReset = () => generatePath(`/${AuthPaths.PasswordReset}`)

export const routeToSignInPasswordResetVerify = (username: string, email: string) =>
  generatePath(
    `/${AuthPaths.PasswordResetVerify}?${usernameQueryParam}=${encodeURIComponent(
      username
    )}&${emailQueryParam}=${encodeURIComponent(email)}`
  )

export const routeToSignUp = () => generatePath(`/${AuthPaths.SignUp}`)
export const routeToDesktopLogin = () => generatePath(`/${AuthPaths.DesktopLogin}`)
export const routeToDesktopLogout = () => generatePath(`/${AuthPaths.DesktopLogout}`)

export const routeToSignUpVerify = (username: string, email: string) =>
  generatePath(
    `/${AuthPaths.SignUpVerify}?${usernameQueryParam}=${encodeURIComponent(
      username
    )}&${emailQueryParam}=${encodeURIComponent(email)}`
  )

/* The without query params functions below should only be used to determine whether the page is in AuthPage */
export const routeToSignUpVerifyWithoutQueryParams = () => generatePath(`/${AuthPaths.SignUpVerify}`)

export const routeToSignInPasswordResetVerifyWithoutQueryParams = () =>
  generatePath(`/${AuthPaths.PasswordResetVerify}`)

export const appendFilePath = (basePath: string, filePath?: string) => (filePath ? `${basePath}/${filePath}` : basePath)

const withQueryParams = (basePath: string, queryParams: Record<string, string>) =>
  isEmpty(queryParams) ? basePath : `${basePath}?${new URLSearchParams(queryParams).toString()}`

export const isUnderFileHistory = (pathname: string) => pathname.includes(`/${RepoSubPaths.FileHistory}/`)

export const AppRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route element={<TitleSetter />}>
        <Route index element={<Navigate to={RootPaths.Dashboard} replace />} />
        <Route path={AuthPaths.DesktopLogin} element={<DesktopLoginPage />} />
        <Route path={AuthPaths.DesktopLogout} element={<DesktopLogoutPage />} />
        <Route path={AuthPaths.SignIn} element={<SignInPage />} />
        <Route path={AuthPaths.PasswordReset} element={<PasswordResetPage />} />
        <Route path={AuthPaths.PasswordResetVerify} element={<PasswordResetVerifyPage />} />
        <Route path={AuthPaths.SignUp} element={<SignUpPage />} />
        <Route path={AuthPaths.SignUpVerify} element={<SignupVerifyPage />} />
        <Route path={RootPaths.Welcome} element={<BrowseRoute />}>
          <Route index element={<WelcomeRoute />}></Route>
        </Route>
        <Route path={RootPaths.Pending} element={<BrowseRoute />}>
          <Route index element={<PendingRoute />}></Route>
        </Route>
        <Route path={RootPaths.Dashboard} element={<BrowseRoute />}>
          <Route index element={<DashboardRoute />}></Route>
        </Route>
        <Route path={RootPaths.Billing} element={<FullPageLoader />} />
        <Route path={RootPaths.Integrations} element={<BrowseRoute />}>
          <Route element={<IntegrationsRoute />}>
            <Route index element={<Navigate to={routeToApiTokens()} replace />}></Route>
            <Route path={IntegrationsPaths.Tokens} element={<TokensTab />} />
            <Route path={IntegrationsPaths.Webhooks} element={<WebhooksTab />} />
          </Route>
        </Route>
        <Route path={RootPaths.Repo} element={<RepoRoute />}>
          <Route path={RepoSubPaths.Workspace} element={<WorkspaceRoute />}>
            <Route path="" element={<Navigate to={WorkspaceSubPaths.Edit} replace />} />
            <Route path={WorkspaceSubPaths.Edit} element={<WorkspaceEditRoute />}>
              <Route path="" element={<LatestCommits />} />
              <Route path={RefSubPaths.FilePath} element={<WorkspaceFileViewerRoute />} />
            </Route>
            <Route path={WorkspaceSubPaths.View} element={<CommitHistoryRoute />}>
              <Route path="" element={<LatestCommits />} />
              <Route path={RefViewSubPaths.Commit} element={<CommitTreeRoute />}>
                <Route path={RefSubPaths.FilePath} element={<CommitFileViewerRoute />} />
              </Route>
            </Route>
            <Route path={WorkspaceSubPaths.Settings} element={<WorkspaceSettingsRoute />} />
            <Route path={WorkspaceSubPaths.Visualize} element={<BranchGraph />} />
            <Route path={RootPaths.Repo} element={<RepoRoute />} />
          </Route>
          <Route path={RepoSubPaths.RepoCommit} element={<DetachedCommitRoute />}>
            <Route path="" element={<Navigate to={CommitSubPaths.View} replace />} />
            <Route path={CommitSubPaths.View} element={<CommitTreeRoute />}>
              <Route path={RefSubPaths.FilePath} element={<CommitFileViewerRoute />} />
            </Route>
            <Route path={CommitSubPaths.Compare} element={<CommitCompareSidePanelRoute />}>
              <Route path={CommitCompareToSubPaths.CompareToCommit}>
                <Route path="" element={<CommitTreeRoute />}>
                  <Route path={RefSubPaths.FilePath} element={<CommitFileViewerRoute />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path={RepoSubPaths.RepoBranch}>
            <Route path="" element={<Navigate to={BranchSubPaths.View} replace />} />
            <Route path={BranchSubPaths.View}>
              <Route index element={<CommitHistoryRoute />}></Route>
              <Route path={RefViewSubPaths.Commit} element={<CommitHistoryRoute />}>
                <Route path="" element={<CommitTreeRoute />}>
                  <Route path={RefSubPaths.FilePath} element={<CommitFileViewerRoute />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path={RepoSubPaths.RepoMerges} element={<MergesRoute />}>
            <Route path="" element={<MergesSidePanelRoute />} />
            <Route path={MergesSubPaths.Merge} element={<MergesConflictsRoute />}>
              <Route path={MergeSubPath.Conflict} element={<MergesConflictViewerRoute />} />
            </Route>
          </Route>
          <Route path={RepoSubPaths.FileHistory}>
            <Route element={<FileHistoryRoute />}>
              <Route path={RefSubPaths.FilePath} element={<CommitFileViewerRoute useFilePathFromContext />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  </BrowserRouter>
)
