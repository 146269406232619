import { useApi } from '../useApi'
import { DetailedMerge, RepositoryMergeManipulationService } from '../../api/coreapi'
import { getIsUserSideFailure, isMergeObsoleteError } from '../../utils/errorClassify'
import { infoToast } from '../../utils/toast'
import { useCallback } from 'react'
import { NavigateFunction, useNavigate } from 'react-router'
import { log } from '../../utils/log'
import { routeToMerges } from '../../Routes'

const OBSOLETE_MERGE: DetailedMerge = {
  id: 'OBSOLETE_MERGE',
  ancestor_commit: '',
  base_ref: '',
  other_ref: '',
  repo_id: '',
  user: {
    id: '',
  },
  conflicts: [],
}

export const handleObsoleteMerge = (repoId: string, mergeId: string, navigate: NavigateFunction) => {
  infoToast('Merge is obsolete, a new one needs to be created')
  log.info('Merge is obsolete, redirecting', { repoId, mergeId })
  navigate(routeToMerges(repoId), { replace: true })
}

export const useMerge = (repoId: string, mergeId: string) => {
  const navigate = useNavigate()
  const {
    data: merge,
    loading,
    refresh,
    error,
  } = useApi<DetailedMerge>(['repos', repoId, 'merges', mergeId], async () => {
    try {
      return RepositoryMergeManipulationService.srcHandlersv2MergeGetOpenMerge({
        repoId,
        mergeId,
      })
    } catch (e) {
      if (isMergeObsoleteError(e)) {
        return OBSOLETE_MERGE
      }
      throw e
    }
  })
  useCallback(() => {
    if (merge?.id === OBSOLETE_MERGE.id) {
      handleObsoleteMerge(repoId, mergeId, navigate)
    }
  }, [merge?.id, mergeId, navigate, repoId])
  return { merge, loading, refresh, failed: error && !getIsUserSideFailure(error).isUserSideFailure }
}
