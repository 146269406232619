import Avatar from '@mui/material/Avatar'
import { toInitials } from '../../utils/textUtils'
import styled from '@emotion/styled'
import isEmpty from 'lodash/isEmpty'
import { Tooltip } from '@mui/material'

type Props = {
  picture?: string
  name?: string
  email: string
  hideTooltip?: boolean
}

const InitialsAvatar = styled(Avatar)`
  color: ${({ theme }) => theme.colors.blue.contrastText};
  background-color: ${({ theme }) => theme.colors.blue.primary};
`

const UserAvatarInternal = ({ picture, displayName }: { picture: string | undefined; displayName: string }) => (
  <>
    {isEmpty(picture) && !isEmpty(displayName) ? (
      <InitialsAvatar>{toInitials(displayName)}</InitialsAvatar>
    ) : (
      <Avatar src={picture} alt={displayName} />
    )}
  </>
)

export const UserAvatar = ({ picture, name, email, hideTooltip }: Props) => {
  const displayName = name || email
  return hideTooltip ? (
    <UserAvatarInternal picture={picture} displayName={displayName} />
  ) : (
    <Tooltip title={displayName || 'N/A'} arrow>
      <div>
        <UserAvatarInternal picture={picture} displayName={displayName} />
      </div>
    </Tooltip>
  )
}
