import { createContext, ReactNode, useCallback, useState } from 'react'
import { Commit, Shelf } from '../../api/coreapi'
import { RevertDialog } from './RevertDialog'
import { useUrlState } from '../../hooks/useUrlState'
import { log } from '../../utils/log'
import { NewBranchDialog } from '../dialogs/NewBranchDialog'
import { routeToBranchView, routeToWorkspaceEdit } from '../../Routes'
import { useNavigate } from 'react-router-dom'
import { ShelfChangesDialog } from '../branch/ShelfChangesDialog'
import { ApplyShelfDialog } from '../branch/ApplyShelfDialog'
import { useBranch } from '../../hooks/api/useBranch'

type CommitActions = 'revert' | 'create-branch-from-commit'
export type CommitActionExec = (action: CommitActions, commit: Commit) => void

interface CommitActionsContextProps {
  doAction: CommitActionExec
}

export const CommitActionContext = createContext<CommitActionsContextProps>({
  doAction: () => {},
})

export const useCommitActions = (): { doAction: CommitActionExec; dialogs: () => ReactNode } => {
  const navigate = useNavigate()
  const { repoId } = useUrlState()
  const [commit, setCommit] = useState<Commit>()
  const [revertDialogIsOpen, setRevertDialogIsOpen] = useState(false)
  const [newBranchDialogIsOpen, setNewBranchDialogIsOpen] = useState(false)
  const [shelfChangesDialogIsOpen, setShelfChangesDialogIsOpen] = useState(false)
  const [applyShelfDialogIsOpen, setApplyShelfDialogIsOpen] = useState(false)
  const [shelves, setShelves] = useState<Shelf[]>([])
  const [branchId, setBranchId] = useState<string>()
  const { branch } = useBranch(repoId, branchId)
  const [workspaceIdForRedirection, setWorkspaceIdForRedirection] = useState<string>()
  const doAction = useCallback<CommitActionExec>((action, commit) => {
    setCommit(commit)
    switch (action) {
      case 'revert':
        setRevertDialogIsOpen(true)
        break
      case 'create-branch-from-commit':
        setNewBranchDialogIsOpen(true)
        break
      default:
        log.error(`Unknown action: ${action}`, { commit })
    }
  }, [])

  const renderDialogs = () =>
    commit &&
    repoId && (
      <>
        <RevertDialog isOpen={revertDialogIsOpen} setOpen={setRevertDialogIsOpen} commit={commit} repoId={repoId} />
        <NewBranchDialog
          isOpen={newBranchDialogIsOpen}
          setOpen={setNewBranchDialogIsOpen}
          sourceBranchOrCommit={commit}
          repoId={repoId}
          onCreated={(branchId, commitId, workspaceId) => {
            if (workspaceId) {
              navigate(routeToWorkspaceEdit(repoId, workspaceId))
            } else {
              navigate(routeToBranchView(repoId, branchId, commitId))
            }
          }}
          onHasPendingChanges={(branchId, workspaceId) => {
            setBranchId(branchId)
            setWorkspaceIdForRedirection(workspaceId)
            setShelfChangesDialogIsOpen(true)
          }}
          onHasShelvedChanges={(shelves, branchId, workspaceId) => {
            setShelves(shelves)
            setBranchId(branchId)
            setWorkspaceIdForRedirection(workspaceId)
            setApplyShelfDialogIsOpen(true)
          }}
        />
        <ShelfChangesDialog
          isOpen={shelfChangesDialogIsOpen}
          setOpen={setShelfChangesDialogIsOpen}
          branch={branch}
          onSuccess={() => {
            workspaceIdForRedirection && navigate(routeToWorkspaceEdit(repoId, workspaceIdForRedirection))
            setWorkspaceIdForRedirection(undefined)
          }}
        />
        <ApplyShelfDialog
          isOpen={applyShelfDialogIsOpen}
          setOpen={setApplyShelfDialogIsOpen}
          branch={branch}
          shelves={shelves}
          onSuccess={() => {
            workspaceIdForRedirection && navigate(routeToWorkspaceEdit(repoId, workspaceIdForRedirection))
            setWorkspaceIdForRedirection(undefined)
          }}
        />
      </>
    )

  return {
    doAction,
    dialogs: renderDialogs,
  }
}
